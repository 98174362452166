import React, { useEffect, useState } from "react";
import { BsJournalX, BsSearch, BsXLg, BsListUl, BsListCheck } from 'react-icons/bs'
import { MdOutlineMailOutline, MdOutlineMarkEmailRead } from 'react-icons/md'
import SuggestionItem from './SuggestionItem'
// import CompletedItem from './CompletedItem'
import ContactMessage from './ContactMessage'
import logo from '../logo-sb.png';

export default function SuggestionPanel() {
  const authToken = sessionStorage.getItem('token')
  const loginEmail = sessionStorage.getItem('profile')
  const [suggestionList, setSuggestionList] = useState([])

  const [showMore, setShowMore] = useState(false)
  const [renderList, setRenderList] = useState(null)
  const [showMoreCount, setShowMoreCount] = useState(0)
  const [completedList, setCompletedList] = useState(null)
  const [showMoreCompleted, setShowMoreCompleted] = useState(false)
  const [renderCompletedList, setRenderCompletedList] = useState(null)
  const [showMoreCompletedCount, setShowMoreCompletedCount] = useState(0)
  
  const [contactMsgList, setContactMsgList] = useState([])
  const [showMorecontactMsg, setShowMorecontactMsg] = useState(false)
  const [renderContactMsgList, setRenderContactMsgList] = useState([])
  const [showMoreContactMsgCount, setShowMoreContactMsgCount] = useState(0)
  const [isRemainingSearched, setIsRemainingSearched] = useState(true)
  const [isCompletedSearched, setIsCompletedSearched] = useState(true)
  const [isContactMsgSearched, setIsContactMsgSearched] = useState(true)

  const [hasError, setHasError] = useState(false)
  const [hasContactMsgError, setHasContactMsgError] = useState(false)
  
  const [checkedContactMsgList, setCheckedContactMsgList] = useState([])
  const [showMoreCheckedContactMsg, setShowMoreCheckedContactMsg] = useState(false)
  const [renderCheckedContactMsgList, setRenderCheckedContactMsgList] = useState([])
  const [showMoreCheckedContactMsgCount, setShowMoreCheckedContactMsgCount] = useState(0)
  const [isCheckedContactMsgSearched, setIsCheckedContactMsgSearched] = useState(true)

  const [hasSearchInput, setHasSearchInput] = useState(false)
  const [searchFormData, setSearchFormData] = useState({
    searchKeyword: '',
  })

  const handleSearch = (e) => {
    // console.log('handleSearch', e.target.value)
    if (e.target.value) setHasSearchInput(true)
    else setHasSearchInput(false)

    setSearchFormData(prevFormData => (
      {
        ...prevFormData,
        [e.target.name]: e.target.value
      }
    ))
  }

  const clearSearch = () => {
    // console.log('clear search clicked!')
    setHasSearchInput(false)
    setSearchFormData({
      searchKeyword: '',
    })
    searchSuggestions(0)
    getContactMsg()
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    // console.log('searchFormData', searchFormData)

    setIsRemainingSearched(true)
    setIsCompletedSearched(true)
    setIsContactMsgSearched(true)
    setIsCheckedContactMsgSearched(true)

    setShowMore(false)
    setShowMoreCompleted(false)
    setShowMorecontactMsg(false)
    setShowMoreCheckedContactMsg(false)

    // search Messages
    searchMessages()

    fetch(`${process.env.REACT_APP_SEARCH_API_URL}?composition=suggestionBox&search=${searchFormData.searchKeyword}&type=suggestion`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
      })
      .then((response) => response.json())
      .then((data) => {
        
        const remainingList = data.filter(item => item.data.suggestionBox.isTodoCompleted === "0")
        const completeList = data.filter(item => item.data.suggestionBox.isTodoCompleted === "1")

        setIsRemainingSearched(false)
        setIsCompletedSearched(false)
        // setIsContactMsgSearched(false)
        // setIsCheckedContactMsgSearched(false)

        dividePagination(remainingList, showMoreCount)
        divideCompletedPagination(completeList, showMoreCompletedCount)
      });
  }

  const searchMessages = () => {
    // console.log('searchMessages')
    fetch(`${process.env.REACT_APP_SEARCH_API_URL}?composition=contactMessages&search=${searchFormData.searchKeyword}&type=heading`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
    .then((response) => response.json())
    .then((data) => {
      
      // console.log('contact msgs data :', data);
      const msgList = data.filter(item => !item.data.contactMessages.isTodoCompleted)
      const completedMsgList = data.filter(item => item.data.contactMessages.isTodoCompleted)
      // console.log(msgList)
      // console.log(completedMsgList)

      setIsContactMsgSearched(false)
      setIsCheckedContactMsgSearched(false)

      divideContactMsgPagination(msgList, showMoreContactMsgCount)
      divideCheckedContactMsgPagination(completedMsgList, showMoreCheckedContactMsgCount)

    });
  }

  // log out
  function logout(e) {
    e.preventDefault()
    sessionStorage.clear()
    window.location.reload()
  }

  async function searchSuggestions(searchParam) {
    setShowMore(false)
    setShowMoreCompleted(false)
    setShowMorecontactMsg(false)

    try {
      setIsRemainingSearched(true)
      setIsCompletedSearched(true)
      // setIsContactMsgSearched(true)
      // setIsCheckedContactMsgSearched(true)

      // await fetch(`${process.env.REACT_APP_SEARCH_API_URL}?composition=suggestionBox&type=isTodoCompleted`, {
      await fetch(`${process.env.REACT_APP_LIST_API_URL}?type=suggestionBox`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
      })
      .then((response) => response.json())
      .then((data) => {
        
        // console.log('searched data', data)
        const remainingList = data.data.filter(item => item.data.suggestionBox.isTodoCompleted === "0").reverse()
        remainingList.sort((a, b) =>  ((b.data.suggestionBox.date) ? new Date(b.data.suggestionBox.date).getTime() : 0) - ((a.data.suggestionBox.date) ? new Date(a.data.suggestionBox?.date).getTime() : 0))
        // console.log('remainingList', remainingList)
        const completeList = data.data.filter(item => item.data.suggestionBox.isTodoCompleted === "1")
        completeList.sort((a, b) =>  ((b.data.suggestionBox.completor) ? new Date(b.data.suggestionBox.completor?.date).getTime() : 0) - ((a.data.suggestionBox.completor) ? new Date(a.data.suggestionBox.completor?.date).getTime() : 0))
        // console.log('completeList', completeList)

        setRenderList(remainingList)
        setCompletedList(completeList)

        setIsRemainingSearched(false)
        setIsCompletedSearched(false)
        // setIsContactMsgSearched(false)
        // setIsCheckedContactMsgSearched(false)

        // console.log('renderList', renderList)

        dividePagination(remainingList, showMoreCount)
        divideCompletedPagination(completeList, showMoreCompletedCount)

        getContactMsg()

      });
    } catch(err) {
      setHasError(true)
      console.log(err.message)
      getContactMsg()
    }
  }

  const dividePagination = (data, showMoreCount) => {
    // console.log('data', data)
    setSuggestionList(data)
    const pageLength = (showMoreCount + 1) * 10
    if (data.length > pageLength) {
      const list = data.slice(0, pageLength)
      setRenderList(list)
      setShowMore(true)
    } else {
      setRenderList(data)
      setShowMore(false)
    }
  }

  const divideCompletedPagination = (data, showMoreCompletedCount) => {
    setCompletedList(data)
    const pageLength = (showMoreCompletedCount + 1) * 10
    if (data.length > pageLength) {
      const list = data.slice(0, pageLength)
      setRenderCompletedList(list)
      setShowMoreCompleted(true)
    } else {
      setRenderCompletedList(data)
      setShowMoreCompleted(false)
    }
  }

  const divideContactMsgPagination = (data, showMoreContactMsgCount) => {
    setContactMsgList(data)
    const pageLength = (showMoreContactMsgCount + 1) * 10
    // console.log('check', data.length, pageLength)
    if (data.length > pageLength) {
      const list = data.slice(0, pageLength)
      setRenderContactMsgList(list)
      setShowMorecontactMsg(true)
    } else {
      setRenderContactMsgList(data)
      setShowMorecontactMsg(false)
    }
  }

  const divideCheckedContactMsgPagination = (data, showMoreCheckedContactMsgCount) => {
    setCheckedContactMsgList(data)
    const pageLength = (showMoreCheckedContactMsgCount + 1) * 10
    // console.log('check', data.length, pageLength)
    if (data.length > pageLength) {
      const list = data.slice(0, pageLength)
      setRenderCheckedContactMsgList(list)
      setShowMoreCheckedContactMsg(true)
    } else {
      setRenderCheckedContactMsgList(data)
      setShowMoreCheckedContactMsg(false)
    }
  }

  const showMoreList = () => {
    const showMoreClickCount = showMoreCount + 1
    setShowMoreCount(showMoreClickCount)
    dividePagination(suggestionList, showMoreClickCount)
  }

  const showMoreCompletedList = () => {
    const showMoreClickCount = showMoreCompletedCount + 1
    setShowMoreCompletedCount(showMoreClickCount)
    divideCompletedPagination(completedList, showMoreClickCount)
  }

  const showMorecontactMsgList = () => {
    const showMoreClickCount = showMoreContactMsgCount + 1
    setShowMoreContactMsgCount(showMoreClickCount)
    divideContactMsgPagination(contactMsgList, showMoreClickCount)
  }

  const showMoreCheckedContactMsgList = () => {
    const showMoreClickCount = showMoreCheckedContactMsgCount + 1
    setShowMoreCheckedContactMsgCount(showMoreClickCount)
    divideCheckedContactMsgPagination(checkedContactMsgList, showMoreClickCount)
  }

  const getContactMsg = async() => {
    setShowMoreCheckedContactMsg(false)
    try {
      setIsContactMsgSearched(true)
      setIsCheckedContactMsgSearched(true)

      // await fetch(`${process.env.REACT_APP_SEARCH_API_URL}?composition=contactMessages`, {
      await fetch(`${process.env.REACT_APP_LIST_API_URL}?type=contactMessages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
      })
      .then((response) => response.json())
      .then((data) => {
        // console.log('contact msgs data :', data);
        const msgList = data.data.filter(item => !item.data.contactMessages.isTodoCompleted)
        msgList.sort((a, b) =>  ((b.data.contactMessages.date) ? new Date(b.data.contactMessages.date).getTime() : 0) - ((a.data.contactMessages.date) ? new Date(a.data.contactMessages.date).getTime() : 0))
        // console.log('msgList', msgList)
        const completedMsgList = data.data.filter(item => item.data.contactMessages.isTodoCompleted)
        // completedMsgList.sort((a, b) => new Date(b.contactMessages.date).getTime() - new Date(a.contactMessages.date).getTime())
        completedMsgList.sort((a, b) =>  ((b.data.contactMessages.completor) ? new Date(b.data.contactMessages.completor?.date).getTime() : 0) - ((a.data.contactMessages.completor) ? new Date(a.data.contactMessages.completor?.date).getTime() : 0))
        // console.log('completedMsgList', completedMsgList)

        setIsContactMsgSearched(false)
        setIsCheckedContactMsgSearched(false)

        divideContactMsgPagination(msgList, showMoreContactMsgCount)
        divideCheckedContactMsgPagination(completedMsgList, showMoreCheckedContactMsgCount)
      })
    } catch(err) {
      setHasContactMsgError(true)
      console.error(err)
    }
  }
  
  useEffect(() => {
    // return () => {
      setTimeout(() => {
        searchSuggestions(0)
        // getContactMsg()
      }, 500)
    // }
  }, [])

  return (
    <>
    <nav className="container-fluid main-nav">
      <div className="container">
        <div className="row align-items-md-center">
          <div className="d-flex align-items-center col-6 col-md-3 order-0">
            <img src={logo} className="sb-logo" alt="logo" />
            <h1 className="ms-3 fs-5 d-none d-sm-block">Suggestion Box</h1>
          </div>
          <div className="col-md-6 my-3 my-md-0 order-2 order-md-1">
            <form className="d-flex position-relative" onSubmit={onSubmitSearch}>
              <input 
                className="form-control search-input px-3" 
                type="input" 
                placeholder="Search"
                name="searchKeyword"
                value={searchFormData.searchKeyword}
                onChange={handleSearch}
                required
              />
              { hasSearchInput &&
                <button type="button" className="btn my-sm-0 btn-close-search" title="Clear Search" onClick={clearSearch}>
                  <BsXLg />
                </button>
              }
              <button type="submit" className="btn my-sm-0 btn-search" title="Search">
                <BsSearch />
              </button>
            </form>
          </div>
          <div className="col-6 col-md-3 d-flex justify-content-end align-items-center flex-wrap order-1 order-md-2">
            <div className="row text-truncate">
              <div className="col-12 col-sm-7 d-flex justify-content-end align-items-center flex-wrap">
                <span className="me-sm-2 text-truncate">{JSON.parse(loginEmail).email}</span>
              </div>
              <div className="col-12 col-sm-5 d-flex justify-content-end">
                <button type='button' className='btn btn-outline-danger btn-sm' onClick={logout}>Log out</button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </nav>
    
    <div className="container" id="todo-section">
      <div className="row">
        <div className="col-sm-12 col-lg-7 m-auto my-4 suggestion-list">
          <ul className="nav nav-tabs" id="suggestionTab" role="tablist">
            <li className="nav-item col-3" role="presentation">
              <button className="nav-link m-auto active" id="remaining-tab" data-bs-toggle="tab" data-bs-target="#remaining" type="button" role="tab" aria-controls="remaining" aria-selected="true">
                <span className="d-none d-sm-block">Remaining List</span>
                <BsListUl size={24} className="d-sm-none" />
              </button>
            </li>
            <li className="nav-item col-3" role="presentation">
              <button className="nav-link m-auto" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed" type="button" role="tab" aria-controls="completed" aria-selected="false">
                <span className="d-none d-sm-block">Completed List</span>
                <BsListCheck size={24} className="d-sm-none" />
              </button>
            </li>
            <li className="nav-item col-3" role="presentation">
              <button className="nav-link m-auto" id="message-tab" data-bs-toggle="tab" data-bs-target="#message" type="button" role="tab" aria-controls="message" aria-selected="false">
                <span className="d-none d-sm-block">Message List</span>
                <MdOutlineMailOutline size={24} className="d-sm-none" />
              </button>
            </li>
            <li className="nav-item col-3" role="presentation">
              <button className="nav-link m-auto" id="completed-message-tab" data-bs-toggle="tab" data-bs-target="#completed-message" type="button" role="tab" aria-controls="completed-message" aria-selected="false">
                <span className="d-none d-sm-block">Checked List</span>
                <MdOutlineMarkEmailRead size={24} className="d-sm-none" />
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="remaining" role="tabpanel" aria-labelledby="remaining-tab">
              <h6 className="d-sm-none my-3">Remaining List</h6>
              {/* { renderList ?
                <>
                  {renderList.map(list => {
                    return (
                      <SuggestionItem suggestionItemDetails={list.suggestionBox} key={list.suggestionBox.id} />
                    )
                  })}
                </>
                : <p className="text-center mt-5">Loading...</p>
              } */}
              { !isRemainingSearched ?
                ( renderList.length ?
                  ( renderList.map(list => {
                    // return <SuggestionItem suggestionItemDetails={list.suggestionBox} key={list.suggestionBox.id} />
                    return <SuggestionItem suggestionItemDetails={list.data.suggestionBox} suggestionItemId={list.id} key={list.id} />
                  }) )
                  :
                  <div className="text-center m-auto my-5 py-5 text-secondary">
                    <BsJournalX size={36} className="block" />
                    <p className="mt-3">No remaining suggestions</p>
                  </div>
                )
                // : <p className="text-center text-secondary my-5 py-5">Loading...</p>
                : (
                  !hasError ? <p className="text-center text-secondary my-5 py-5">Loading...</p>
                  : <p className="text-center text-danger my-5 py-5">There is something wrong!</p>
                )
              }

              { showMore ? 
                <div className="m-auto text-center">
                  <button className="btn btn-primary" onClick={showMoreList}>Show suggestion list more...</button>
                </div>
                : '' 
              }
            </div>
            <div className="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-tab">
              <h6 className="d-sm-none my-3">Completed List</h6>
              { !isCompletedSearched ?
                ( renderCompletedList.length ?
                  ( renderCompletedList.map(list => {
                    // return <CompletedItem suggestionItemDetails={list.suggestionBox} key={list.suggestionBox.id} />
                    return <SuggestionItem suggestionItemDetails={list.data.suggestionBox} suggestionItemId={list.id} key={list.id} />
                  }) )
                  :
                  <div className="text-center m-auto my-5 py-5 text-secondary">
                    <BsJournalX size={36} className="block" />
                    <p className="mt-3">No completed suggestions</p>
                  </div>
                )
                // : <p className="text-center text-secondary my-5 py-5">Loading...</p>
                : (
                  !hasError ? <p className="text-center text-secondary my-5 py-5">Loading...</p>
                  : <p className="text-center text-danger my-5 py-5">There is something wrong!</p>
                )
              }

              { showMoreCompleted ? 
                <div className="m-auto text-center">
                  <button className="btn btn-primary" onClick={showMoreCompletedList}>Show completed list more...</button>
                </div>
                : '' 
              }
            </div>

            <div className="tab-pane fade" id="message" role="tabpanel" aria-labelledby="message-tab">
              <h6 className="d-sm-none my-3">Message List</h6>
              { !isContactMsgSearched ?
                ( renderContactMsgList.length ?
                  ( renderContactMsgList.map(list => {
                    return <ContactMessage contactMessage={list.data.contactMessages} contactMessageId={list.id} key={list.id} />
                  }) )
                  :
                  <div className="text-center m-auto my-5 py-5 text-secondary">
                    <BsJournalX size={36} className="block" />
                    <p className="mt-3">No messages</p>
                  </div>
                )
                // : <p className="text-center text-secondary my-5 py-5">Loading...</p>
                : (
                  !hasContactMsgError ? <p className="text-center text-secondary my-5 py-5">Loading...</p>
                  : <p className="text-center text-danger my-5 py-5">There is something wrong!</p>
                )
              }

              { showMorecontactMsg ? 
                <div className="m-auto text-center">
                  <button className="btn btn-primary" onClick={showMorecontactMsgList}>Show messages more...</button>
                </div>
                : '' 
              }
            </div>

            <div className="tab-pane fade" id="completed-message" role="tabpanel" aria-labelledby="completed-message-tab">
              <h6 className="d-sm-none my-3">Checked List</h6>
              { !isCheckedContactMsgSearched ?
                ( renderCheckedContactMsgList.length ?
                  ( renderCheckedContactMsgList.map(list => {
                    return <ContactMessage contactMessage={list.data.contactMessages} contactMessageId={list.id} key={list.id} />
                  }) )
                  :
                  <div className="text-center m-auto my-5 py-5 text-secondary">
                    <BsJournalX size={36} className="block" />
                    <p className="mt-3">No checked messages</p>
                  </div>
                )
                // : <p className="text-center text-secondary my-5 py-5">Loading...</p>
                : (
                  !hasContactMsgError ? <p className="text-center text-secondary my-5 py-5">Loading...</p>
                  : <p className="text-center text-danger my-5 py-5">There is something wrong!</p>
                )
              }

              { showMoreCheckedContactMsg ? 
                <div className="m-auto text-center">
                  <button className="btn btn-primary" onClick={showMoreCheckedContactMsgList}>Show checked messages more...</button>
                </div>
                : '' 
              }
            </div>

          </div>
        </div>

      </div>
      
    </div>
  </>
  )
}