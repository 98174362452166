import { useState } from "react";
import logo from "../logo-sb.png";

export default function Login({ checkLoginStatus }) {
  let authToken = "";
  let logEmail = "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginText, setLoginText] = useState("Log In");
  const [showError, setShowError] = useState(false);

  function updateEmail(e) {
    setEmail(e.target.value);
  }

  function updatePassword(e) {
    setPassword(e.target.value);
  }

  async function login(e) {
    e.preventDefault();
    setLoginText("Logging In...");
    setShowError(false);

    let data = {
      email: email,
      password: password,
    };
    data = JSON.stringify(data);

    fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('status', data.status, typeof data.status)
        if (data.status !== 200) {
          setLoginText("Log In");
          setShowError(true);
        }

        authToken = data.data.token;
        logEmail = data.data.email;
        checkLoginStatus(true);
        setStorage();
      });
  }

  const setStorage = () => {
    sessionStorage.setItem("isLoggedIn", "true");
    sessionStorage.setItem("token", authToken);
    sessionStorage.setItem("profile", JSON.stringify({ email: logEmail }));
  };

  return (
    <div className="container" id="welcome-section">
      <div className="row login-section">
        <div className="col-12 col-lg-4 m-auto mt-3 mb-5 login-detail">
          <img src={logo} alt="Suggestion Box logo" className="logo" />
          <h1 className="text-center mt-4 mb-5 fs-5">Suggestion Box</h1>
          <form id="login-form" onSubmit={login}>
            <div className="mb-3">
              <label forhtml="loginEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="loginEmail"
                name="loginEmail"
                required
                onChange={updateEmail}
              />
            </div>
            <div className="mb-3">
              <label forhtml="loginPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="loginPassword"
                name="loginPassword"
                required
                onChange={updatePassword}
              />
            </div>
            {showError && (
              <p className="mt-2 mb-3 text-danger">
                Email or password is incorrect
              </p>
            )}
            <button
              type="submit"
              className={`${
                loginText !== "Log In" ? "disabled" : ""
              } btn btn-primary btn-login`}
            >
              {loginText}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
