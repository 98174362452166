import React from "react";
import { useState, useEffect } from 'react';
import Login from '../components/Login'
import SuggestionPanel from '../components/SuggestionPanel'

export default function Home() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkStatus = (loginStatus) => {
    if (loginStatus) {
      // console.log('loginStatus', loginStatus)
      setIsLoggedIn(true)
    }
  }

  useEffect(() => {
    // return () => {
      // let token = sessionStorage.getItem("token");
      let loggedIn = sessionStorage.getItem("isLoggedIn");
      // console.log(loggedIn, token)
      
      if (loggedIn) { 
        // console.log('from useEffect')
        checkStatus(true)
      }
    // }
  }, [])

  return (
    <>
      { isLoggedIn ? 
        <SuggestionPanel /> : <Login checkLoginStatus={checkStatus}/> 
      }
    </>
  )
}
