import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className='not-found'>
      <div className='not-found-content'>
        <h1>404</h1>
        <p>Page Not Found</p>
        <Link to={'/'} className='btn btn-primary'>Go Suggestions Home</Link>
      </div>
    </div>
  )
}